import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { environment } from "../config";
import service from "../service";

const HistoryClient = ({ details, user }) => {
  const reserve = [
    "phone",
    "name",
    "email",
    "document",
    "hasVerificationField",
    "verificationFields",
  ];
  const filesEnv = environment.storage;

  function getOriginalName(download, label) {
    const file = details.files.find((data) => data._id === download);
    if (file && file.originalname) {
      const name = file.originalname;
      if (name.length > 8) {
        return name.substring(0, 4) + "..." + name.substring(name.length - 4);
      } else {
        return name || label;
      }
    } else {
      return label;
    }
  }

  function findValueByLabel(labels, data, type) {
    const labelSet = new Set(labels.map((label) => label.toLowerCase().trim()));
    if (data === undefined) {
      return undefined;
    }
    const field = data.find(
      (el) => el._id === type && labelSet.has(el.label.trim().toLowerCase())
    );
    if (field === undefined) {
      return undefined;
    }

    return field.value;
  }
  const nameField = details?.customFields?.data?.find(
    (el) => el.type === "name"
  );
  const emailField = details?.customFields?.data?.find(
    (el) => el.type === "email"
  );
  const phoneField = details?.customFields?.data?.find(
    (el) => el.type === "phone"
  );
  return (
    <Grid container spacing={1} style={{ padding: 24 }}>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box>
          <Box style={{ color: "#535353" }}>Cliente</Box>
          <Box
            style={{
              marginTop: 12,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {details && details.contact
              ? details.contact.firstname === "No especificado" &&
                details.contact.lastname === "No especificado"
                ? "No especificado"
                : service.getName(details.contact)
              : nameField?.value ||
                findValueByLabel(
                  [
                    "nombre",
                    "nombre completo",
                    "apellido",
                    "su nombre",
                    "name",
                    "first name",
                    "lastname",
                    "fullname",
                    "full name",
                    "tu nombre",
                    "tu nombre completo",
                    "primer nombre",
                    "tu primer nombre",
                    "su primer nombre",
                    "nombre de pila",
                    "given name",
                  ],
                  details?.customFields?.data,
                  "text"
                ) ||
                "No especificado"}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box>
          <Box style={{ color: "#535353" }}>Email</Box>
          <Box
            style={{
              marginTop: 12,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {details && details.contact
              ? `${details.contact.email}`
              : emailField?.value ||
                findValueByLabel(
                  [
                    "correo",
                    "correo electrónico",
                    "correo electronico",
                    "email",
                    "su gmail",
                  ],
                  details?.customFields?.data,
                  "email"
                ) ||
                "No especificado"}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
        <Box>
          <Box style={{ color: "#535353" }}>Teléfono</Box>
          <Box
            style={{
              marginTop: 12,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {details && details.contact
              ? `${details.contact.phone}`
              : phoneField?.value ||
                findValueByLabel(
                  [
                    "telefono",
                    "teléfono",
                    "phone",
                    "phone number",
                    "número de teléfono",
                    "número de telefono",
                    "numero de teléfono",
                    "numero de telefono",
                  ],
                  details?.customFields?.data,
                  "phone"
                ) ||
                "No especificado"}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ marginTop: 0 }}>
        {details?.channel !== "whatsapp" &&
          details?.channel !== "facebook" &&
          details?.channel !== "instagram" && (
            <Box>
              <Box style={{ color: "#535353" }}>Navegador</Box>
              <Box
                style={{
                  marginTop: 12,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {details?.clienInfo?.browser?.name}
              </Box>
            </Box>
          )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ marginTop: 24 }}>
        {details?.channel !== "whatsapp" &&
          details?.channel !== "facebook" &&
          details?.channel !== "instagram" && (
            <Box>
              <Box style={{ color: "#535353" }}>Nav versión</Box>
              <Box
                style={{
                  marginTop: 12,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {details?.clienInfo?.browser?.version}
              </Box>
            </Box>
          )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ marginTop: 24 }}>
        {details?.channel !== "whatsapp" &&
          details?.channel !== "facebook" &&
          details?.channel !== "instagram" && (
            <Box>
              <Box style={{ color: "#535353" }}>Sistema operativo</Box>
              <Box
                style={{
                  marginTop: 12,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {details?.clienInfo?.os?.name}
              </Box>
            </Box>
          )}
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} xl={3} style={{ marginTop: 24 }}>
        {details?.channel !== "whatsapp" &&
          details?.channel !== "facebook" &&
          details?.channel !== "instagram" && (
            <Box>
              <Box style={{ color: "#535353" }}>Dirección IP</Box>
              <Box
                style={{
                  marginTop: 12,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {details?.clienInfo?.ip}
              </Box>
            </Box>
          )}
      </Grid>

      {details?.customFields &&
        details?.customFields.data &&
        details?.customFields.data
          .filter(
            (filt) =>
              filt.type !== "email" &&
              filt.type !== "phone" &&
              filt.type !== "name" &&
              filt.label.toLowerCase().trim() !== "nombre" &&
              filt.label.toLowerCase().trim() !== "nombre completo" &&
              filt.label.toLowerCase().trim() !== "apellido" &&
              filt.label.toLowerCase().trim() !== "su nombre" &&
              filt.label.trim().toLowerCase() !== "name" &&
              filt.label.trim().toLowerCase() !== "first name" &&
              filt.label.trim().toLowerCase() !== "full name" &&
              filt.label.trim().toLowerCase() !== "fullname" &&
              filt.label.toLowerCase().trim() !== "correo" &&
              filt.label.toLowerCase().trim() !== "correo electrónico" &&
              filt.label.toLowerCase().trim() !== "correo electronico" &&
              filt.label.toLowerCase().trim() !== "email" &&
              filt.label.toLowerCase().trim() !== "su gmail" &&
              filt.label.toLowerCase().trim() !== "telefono" &&
              filt.label.toLowerCase().trim() !== "teléfono" &&
              filt.label.toLowerCase().trim() !== "phone" &&
              filt.label.toLowerCase().trim() !== "phone number" &&
              filt.label.toLowerCase().trim() !== "número de teléfono" &&
              filt.label.toLowerCase().trim() !== "número de telefono" &&
              filt.label.toLowerCase().trim() !== "numero de teléfono" &&
              filt.label.toLowerCase().trim() !== "numero de telefono"
          )
          .map((el, index) => {
            return (
              <Grid
                key={index}
                item
                xs={12}
                sm={12}
                md={3}
                lg={3}
                xl={3}
                style={{ marginTop: 24 }}
              >
                <Box>
                  <Box style={{ color: "#535353" }}>
                    {el.placeholder || el.label}
                  </Box>
                  {el._id !== "textarea" &&
                    el._id !== "upload" &&
                    el._id !== "download" && (
                      <Box
                        style={{
                          marginTop: 12,
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {el.value !== "" ? el.value : "No especificado"}
                      </Box>
                    )}
                  {el._id === "textarea" && (
                    <Box
                      style={{
                        marginTop: 12,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {el.value !== "" ? el.value : "No especificado"}
                    </Box>
                  )}
                  {el._id === "upload" && (
                    <Box
                      style={{
                        marginTop: 12,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <a
                        href={
                          el.download
                            ? filesEnv + "/c/" + el.download
                            : "javascript:void(0)"
                        }
                        target={el.download ? "_blank" : ""}
                      >
                        {getOriginalName(el.download, el.label)}
                      </a>
                    </Box>
                  )}
                  {el._id === "download" && (
                    <Box
                      style={{
                        marginTop: 12,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <a href={filesEnv + "/c/" + el.download} target="_blank">
                        {getOriginalName(el.download, el.label)}
                      </a>
                    </Box>
                  )}
                </Box>
              </Grid>
            );
          })}
    </Grid>
  );
};

export default HistoryClient;
