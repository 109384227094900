import { useState } from "react";
import { getCanalIcon } from "../utils";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import service from "../service";

function AttentionInformation({ currentChat, groups, user }) {
  const [open, setOpen] = useState(false);
  return (
    <div
      style={{
        minHeight: "150px",

        backgroundColor: "#FFFFFF",
        padding: "12px",
        marginBottom: "8px",
        textAlign: "center",
      }}
    >
      {}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "center",
        }}
      >
        <div
          style={{
            border: "2px solid #01962D",
            borderRadius: "100%",
            display: "flex",
            padding: "3px 4px 4px 4px",
            marginBottom: 12,
          }}
        >
          <img
            src={getCanalIcon(currentChat?.channel)}
            style={{
              width: 100,
              height: 100,
              borderRadius: "100%",
              // padding: 4,
            }}
          />
        </div>
      </div>

      <p
        style={{
          color: "#394154",
          fontWeight: 600,
          fontSize: 18,
          marginTop: -5,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {currentChat && currentChat.contact
          ? currentChat.contact.firstname === "No especificado" &&
            currentChat.contact.lastname === "No especificado"
            ? "No especificado"
            : service.getName(currentChat.contact)
          : !currentChat?.customFields?.data?.find(
              (el) =>
                el._id === "text" &&
                (el.label.trim().toLowerCase() == "nombre" ||
                  el.label.trim().toLowerCase() == "nombre completo" ||
                  el.label.trim().toLowerCase() == "apellido" ||
                  el.label.trim().toLowerCase() == "su nombre" ||
                  el.label.trim().toLowerCase() == "name" ||
                  el.label.trim().toLowerCase() == "first name" ||
                  el.label.trim().toLowerCase() == "full name" ||
                  el.label.trim().toLowerCase() == "fullname")
            )?.value
          ? "No especificado"
          : currentChat?.customFields?.data?.find(
              (el) =>
                el._id === "text" &&
                (el.label.trim().toLowerCase() == "nombre" ||
                  el.label.trim().toLowerCase() == "nombre completo" ||
                  el.label.trim().toLowerCase() == "apellido" ||
                  el.label.trim().toLowerCase() == "su nombre" ||
                  el.label.trim().toLowerCase() == "name" ||
                  el.label.trim().toLowerCase() == "first name" ||
                  el.label.trim().toLowerCase() == "full name" ||
                  el.label.trim().toLowerCase() == "fullname")
            )?.value}
      </p>
      <p style={{ marginTop: -12, fontSize: 14, fontWeight: 600 }}>
        {" "}
        Información de atención
      </p>
      <p style={{ marginTop: -10, fontSize: 14 }}>
        {`Tipo de atención:  ${currentChat && currentChat.ability.name}`}
      </p>
      <p style={{ marginTop: -10, fontSize: 14 }}>
        {`Grupo: ${
          currentChat &&
          groups.find((group) => group._id === currentChat.group._id)?.name
        }`}
      </p>
      {currentChat.channel === "web" && (
        <div style={{ marginBottom: "8px" }}>
          <List disablePadding>
            <ListItemButton
              onClick={handleOpen}
              style={{ color: "#394154" }}
              disableGutters
            >
              <ListItemText primary="Datos del navegador" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div">
                <ListItemText
                  primary={
                    <>
                      <strong>IP del cliente</strong>
                      {`: ${currentChat ? currentChat.clienInfo.ip : ""}`}
                    </>
                  }
                  disableTypography={true}
                  style={{ fontSize: "14px" }}
                />
                <ListItemText
                  primary={
                    <>
                      <strong>Navegador</strong>
                      {`: ${
                        currentChat ? currentChat.clienInfo.browser.name : ""
                      }`}
                    </>
                  }
                  disableTypography={true}
                  style={{ fontSize: "14px" }}
                />

                <ListItemText
                  primary={
                    <>
                      <strong>Versión</strong>
                      {`: ${
                        currentChat ? currentChat.clienInfo.browser.version : ""
                      }`}
                    </>
                  }
                  disableTypography={true}
                  style={{ fontSize: "14px" }}
                />

                <ListItemText
                  primary={
                    <>
                      <strong>Sistema Operativo</strong>
                      {`: ${currentChat ? currentChat.clienInfo.os.name : ""}`}
                    </>
                  }
                  disableTypography={true}
                  style={{ fontSize: "14px" }}
                />

                <ListItemText
                  primary={
                    <>
                      <strong>Sistema Versión</strong>
                      {`: ${
                        currentChat ? currentChat.clienInfo.os.version : ""
                      }`}
                    </>
                  }
                  disableTypography={true}
                  style={{ fontSize: "14px" }}
                />
              </List>
            </Collapse>
          </List>
        </div>
      )}
    </div>
  );

  function handleOpen() {
    setOpen(!open);
  }
}

export default AttentionInformation;
