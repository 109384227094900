import { getCanalIcon } from "../utils";
import DateObject from "react-date-object";

import transferIcon from "../assets/transferIcon.svg";

const LineChatItem = (props) => {
  const clientJoin = props.history.find((msg) => msg.action === "client_join");
  return (
    <div
      style={{
        height: "80px",
        backgroundColor:
          props.state.currentChat?._id === props.id
            ? "rgba(27, 7, 121, 0.11)"
            : "#FFFFFF",
        padding: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderBottom: !props.isLast && "solid 1px #D6D8E8",
      }}
      onClick={() => {
        if (props.state.currentChat?._id !== props.id)
          props.getCurrentChat(props.item._id, props.item);
      }}
      className="item-chat-vl-agent"
    >
      <div>
        <img
          src={getCanalIcon(props.canal)}
          style={{ width: "48px", marginRight: "8px" }}
          alt="canal"
        />
      </div>
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginRight: 4,
        }}
      >
        <div
          style={{
            fontWeight: 600,
            color: "#394154",
          }}
        >
          {props.name}
        </div>
        <div style={{ color: "#394154" }}>{props.ability}</div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            fontSize: "12px",
            color: "#394154",
            marginBottom: "4px",
            color: props.count > 0 && "#EA345F  ",
          }}
        >
          {props.messages.length > 0 &&
          (props.messages[props.messages.length - 1] || clientJoin.date)
            ? new DateObject(
                new Date(props.messages[props.messages.length - 1]?.date)
              ).format("hh:mm a")
            : new DateObject(new Date(clientJoin?.date)).format("hh:mm a")}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: props.count === 0 && "end",
          }}
        >
          {props.item?.transferredBy && (
            <img src={transferIcon} alt="" style={{ width: 26 }} />
          )}
          {props?.count > 0 && (
            <div
              style={{
                fontSize: "14px",
                backgroundColor: "#EA345F",
                padding: "2px",
                marginTop: 3,
                color: "#ffffff",
                width: "26px",
                display: "flex",
                justifyContent: "center",
                marginLeft: "auto",
                borderRadius: "100%",
                fontWeight: "500",
              }}
            >
              {props?.count}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LineChatItem;
