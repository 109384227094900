import { useState, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import { Autocomplete, MenuItem, Select, TextField } from "@mui/material";
import service from "../service";

function ContactForm({
  createContact,
  user,
  currentChat,
  contactsInfo,
  mergeContact,
}) {
  const [fullname, setFullName] = useState("");

  const [email, setEmail] = useState(
    currentChat?.contact?.email === "No especificado"
      ? ""
      : currentChat?.contact?.email
  );

  const [phone, setPhone] = useState(
    currentChat?.contact?.phone === "No especificado"
      ? ""
      : currentChat?.contact?.phone
  );
  const [currentContact, setCurrentContact] = useState(currentChat?.contact);
  const [code, setCode] = useState("58");
  const phoneCodes = [" ", "58", "57", "56", "1", "54"];
  const [additionalFields, setAdditionalFields] = useState([]);

  function getOptionLabel(option, input) {
    if (input === "phone") {
      return option.phone === "No especificado" ? "" : option.phone;
    }
    if (input === "name") return service.getName(option);
    if (input === "email") return option.email;
  }

  function getOptionSelected(option, value) {
    return option._id === value._id;
  }
  useEffect(() => {
    if (currentChat?.contact) {
      const areaCode = phoneCodes.find((code) =>
        currentChat?.contact?.phone.startsWith(code)
      );
      if (areaCode) setCode(areaCode);
      else setCode("58");
      if (currentChat?.contact?.data) {
        setAdditionalFields([]);
        const data = user.preferences.additionalFieldsContact;
        data.forEach((data) => {
          data.value = "";
          if (currentContact._id === currentChat?.contact._id) {
            currentChat?.contact?.data.forEach((field) => {
              if (data.key === field.key) {
                data.value = field.value;
              }
            });
          } else {
            currentContact.data?.forEach((field) => {
              if (data.key === field.key) {
                data.value = field.value;
              }
            });
          }
        });
        setAdditionalFields(data);
      } else {
        setAdditionalFields([]);
        const data = user.preferences.additionalFieldsContact;
        data.forEach((data) => {
          data.value = "";
        });
        setAdditionalFields(data);
      }
    }
  }, [currentChat, currentContact]);

  const handleChange = (event, key) => {
    const additionalFieldsDB = additionalFields;

    let inputs = additionalFieldsDB.slice();
    for (let i in inputs) {
      if (inputs[i].key == key) {
        inputs[i].value = event.target.value;
        break;
      }
    }
    setAdditionalFields(inputs);
  };

  const regexForName = /^[(ñA-Za-zÀ-ú\s)'-]*$/;
  const regexForEmail =
    /^[A-Za-z0-9_]+(\.[_A-Za-z0-9_]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,15})$/;
  const regexForPhone = /^[0-9]*$/;
  return (
    <>
      <FormControl
        fullWidth
        style={{ marginBottom: "6px" }}
        // disabled={!currentChat}
      >
        <div style={{ paddingLeft: 16, fontSize: 10 }}>
          {"Nombre y apellido"}
        </div>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          getOptionLabel={(option) => getOptionLabel(option, "name")}
          getOptionSelected={getOptionSelected}
          value={contactsInfo.find(
            (option) => option._id === currentContact?._id
          )}
          options={contactsInfo.filter(
            (e) =>
              (e.firstname !== "" && e.firstname !== "No especificado") ||
              (e.lastname !== "" && e.lastname !== "No especificado")
          )}
          onInputChange={(event, value) => {
            console.log({ value });
            setFullName(value);
          }}
          onChange={(event, value) => {
            const contact = contactsInfo.find((el) => el._id === value._id);
            const current = { ...currentContact };

            for (const key in contact) {
              console.log({ key });

              // Verificar si la propiedad existe en ambos objetos y si el valor en contact está vacío
              if (
                contact.hasOwnProperty(key) &&
                current.hasOwnProperty(key) &&
                contact[key] === ""
              ) {
                // Actualizar el valor en contact con el valor no vacío de currentContact
                contact[key] = current[key];
              }
            }
            setCurrentContact(contact);
            setCode(contact.code ? contact.code : " ");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
              size="small"
              style={{
                height: "40px",
                borderRadius: "30px",
                backgroundColor: "#F3F6F9",
              }}
              placeholder="Nombre y apellido"
            />
          )}
        />
      </FormControl>
      {!regexForName.test(fullname) && fullname !== "" && (
        <div
          style={{
            paddingLeft: "12px",
            fontSize: "12px",
            marginBottom: "6px",
            color: "red",
          }}
        >
          Formato no válido
        </div>
      )}

      <FormControl fullWidth style={{ marginBottom: "6px" }}>
        <div style={{ paddingLeft: 16, fontSize: 10 }}>{"Correo"}</div>
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          getOptionLabel={(option) => getOptionLabel(option, "email")}
          value={contactsInfo.find(
            (option) => option._id === currentContact?._id
          )}
          getOptionSelected={getOptionSelected}
          options={contactsInfo.filter(
            (e) => e.email !== "" && e.email !== "No especificado"
          )}
          onInputChange={(event, value) => {
            setEmail(value);
          }}
          onChange={(event, value) => {
            console.log({ value });
            const contact = contactsInfo.find((el) => el._id === value._id);
            const current = { ...currentContact };

            for (const key in contact) {
              // Verificar si la propiedad existe en ambos objetos y si el valor en contact está vacío
              if (
                contact.hasOwnProperty(key) &&
                current.hasOwnProperty(key) &&
                contact[key] === ""
              ) {
                // Actualizar el valor en contact con el valor no vacío de currentContact
                contact[key] = current[key];
              }
            }

            setCurrentContact(contact);
            setCode(contact.code ? contact.code : " ");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
              size="small"
              style={{
                height: "40px",
                borderRadius: "30px",
                backgroundColor: "#F3F6F9",
              }}
              placeholder="Correo"
            />
          )}
        />
      </FormControl>
      {!regexForEmail.test(email) && email !== "" && (
        <div
          style={{
            paddingLeft: "12px",
            fontSize: "12px",
            marginBottom: "6px",
            color: "red",
          }}
        >
          Formato no válido
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div style={{ width: "30%" }}>
          <FormControl fullWidth style={{ marginBottom: "6px" }}>
            <div style={{ paddingLeft: 16, fontSize: 10 }}>{"Código"}</div>
            <Select
              value={code}
              onChange={(event) => setCode(event.target.value)}
              style={{
                height: 40,
                background: "rgb(243, 246, 249)",
                borderRadius: 30,
              }}
            >
              {phoneCodes.map((code, index) => (
                <MenuItem
                  id={`code-${code}-${index}`}
                  key={code}
                  value={code}
                  style={{
                    borderRadius: 4,
                    // padding: 0,
                    padding: 12,
                    minHeight: 0,
                  }}
                >
                  {code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div style={{ width: "100%" }}>
          <FormControl fullWidth style={{ marginBottom: "6px" }}>
            <div style={{ paddingLeft: 16, fontSize: 10 }}>{"Teléfono"}</div>
            <Autocomplete
              freeSolo
              id="free-solo-2-demo"
              disableClearable
              getOptionLabel={(option) => getOptionLabel(option, "phone")}
              getOptionSelected={getOptionSelected}
              value={contactsInfo.find(
                (option) => option._id === currentContact?._id
              )}
              options={contactsInfo.filter(
                (e) => e.phone !== "" && e.phone !== "No especificado"
              )}
              onInputChange={(event, value) => {
                if (value !== "" && value !== "No especificado") {
                  setPhone(Number(value).toString());
                } else {
                  setPhone("");
                }
              }}
              onChange={(event, value) => {
                const contact = contactsInfo.find((el) => el._id === value._id);
                const current = { ...currentContact };

                for (const key in contact) {
                  // Verificar si la propiedad existe en ambos objetos y si el valor en contact está vacío
                  if (
                    contact.hasOwnProperty(key) &&
                    current.hasOwnProperty(key) &&
                    contact[key] === ""
                  ) {
                    // Actualizar el valor en contact con el valor no vacío de currentContact
                    contact[key] = current[key];
                  }
                }

                setCurrentContact(contact);
                setCode(contact.code ? contact.code : " ");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                  size="small"
                  style={{
                    height: "40px",
                    borderRadius: "30px",
                    backgroundColor: "#F3F6F9",
                  }}
                  placeholder="Teléfono"
                />
              )}
            />
          </FormControl>
        </div>
      </div>
      {!regexForPhone.test(phone) && phone !== "" && (
        <div
          style={{
            paddingLeft: "12px",
            fontSize: "12px",
            marginBottom: "6px",
            color: "red",
          }}
        >
          Formato no válido
        </div>
      )}
      {additionalFields.map((field) => (
        <FormControl
          fullWidth
          style={{ marginBottom: "6px" }}

          // disabled={!currentChat}
        >
          <div style={{ paddingLeft: 16, fontSize: 10 }}>{field.label}</div>
          <OutlinedInput
            onChange={(event) => {
              handleChange(event, field.key);
            }}
            name={""}
            value={field.value || ""}
            inputProps={{
              maxLength: 50,
            }}
            autoComplete="off"
            style={{
              height: "40px",
              borderRadius: "30px",
              backgroundColor: "#F3F6F9",
            }}
            type={"text"}
            placeholder={field.placeholder}
          />
        </FormControl>
      ))}

      <div
        style={{
          marginLeft: "12px",
          marginRight: "12px",
          marginTop: "20px",
        }}
      >
        <Button
          onClick={() => {
            const splitName = fullname.trim().split(" ");
            if (currentContact._id === currentChat?.contact._id) {
              createContact({
                firstname: splitName[0] ? splitName[0] : "",
                lastname: splitName[1] ? splitName[1] : "",
                email,
                phone: phone !== "" ? `${code}${phone}` : phone,
                data: additionalFields,
              });
            } else {
              mergeContact({
                firstname: splitName[0] ? splitName[0] : "",
                lastname: splitName[1] ? splitName[1] : "",
                email,
                phone: phone !== "" ? `${code}${phone}` : phone,
                data: additionalFields,
                from: currentChat?.contact._id,
                to: currentContact._id,
                chatId: currentChat._id,
              });
            }
          }}
          variant="contained"
          fullWidth
          style={{
            textTransform: "inherit",
            borderRadius: "25.5px",
            backgroundColor:
              code === " " ||
              (phone !== "" && phone?.length < 8) ||
              (phone !== "" && phone?.length > 15) ||
              fullname === "" ||
              (email === "" && phone === "") ||
              (!regexForName.test(fullname) && fullname !== "") ||
              (!regexForPhone.test(phone) && phone !== "") ||
              (!regexForEmail.test(email) && email !== "")
                ? "#C5C5C5"
                : "#EA345F",
            color: "white",
          }}
          disabled={
            code === " " ||
            (phone !== "" && phone?.length < 8) ||
            (phone !== "" && phone?.length > 15) ||
            fullname === "" ||
            // lastname === "" ||
            (email === "" && phone === "") ||
            (!regexForName.test(fullname) && fullname !== "") ||
            (!regexForPhone.test(phone) && phone !== "") ||
            (!regexForEmail.test(email) && email !== "")
          }
        >
          {currentContact._id === currentChat?.contact._id
            ? "Guardar Datos"
            : "Guardar y actualizar datos"}
        </Button>
      </div>
    </>
  );
}

export default ContactForm;
