import { getCanalIcon } from "../utils";

import transferIcon from "../assets/transferIcon.svg";
import service from "../service";
const LinePeopleItem = ({ item, groups }) => {
  return (
    <div
      style={{
        width: "100px",
        marginRight: "8px",
        textAlign: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={getCanalIcon(item.channel ? item.channel : "hook")}
          style={{ width: "48px", height: "48px" }}
          alt="canal"
        />
        {item?.transferredBy && (
          <img
            src={transferIcon}
            alt=""
            style={{
              width: 18,
              marginLeft: "auto",
              marginTop: -15,
              marginRight: 30,
            }}
          />
        )}
      </div>
      <div
        style={{
          color: "#394154",

          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "16px",
        }}
      >
        {item && item.contact
          ? service.getName(item.contact)
          : !item?.customFields?.data?.find(
              (el) =>
                el._id === "text" &&
                (el.label.trim().toLowerCase() == "nombre" ||
                  el.label.trim().toLowerCase() == "nombre completo" ||
                  el.label.trim().toLowerCase() == "apellido" ||
                  el.label.trim().toLowerCase() == "su nombre" ||
                  el.label.trim().toLowerCase() == "name" ||
                  el.label.trim().toLowerCase() == "first name" ||
                  el.label.trim().toLowerCase() == "full name" ||
                  el.label.trim().toLowerCase() == "fullname")
            )?.value
          ? "N/A"
          : item?.customFields?.data?.find(
              (el) =>
                el._id === "text" &&
                (el.label.trim().toLowerCase() == "nombre" ||
                  el.label.trim().toLowerCase() == "nombre completo" ||
                  el.label.trim().toLowerCase() == "apellido" ||
                  el.label.trim().toLowerCase() == "su nombre" ||
                  el.label.trim().toLowerCase() == "name" ||
                  el.label.trim().toLowerCase() == "first name" ||
                  el.label.trim().toLowerCase() == "full name" ||
                  el.label.trim().toLowerCase() == "fullname")
            )?.value}
      </div>
      <div style={{ color: "#394154", fontSize: "14px" }}>
        {groups.find((group) => group._id === item.group).name}
      </div>
      <div
        style={{
          color: "#394154",
          fontSize: "14px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {item.ability.name}
      </div>
    </div>
  );
};

export default LinePeopleItem;
