import { environment } from "../config";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DateObject from "react-date-object";

import transferIcon from "../assets/transferIcon2.svg";
import service from "../service";

function ChatItem({
  isUser,
  message,
  client,
  id,
  name,
  date,
  agents,
  item,
  setIsImgView,
  setSelectedImage,
  currentChat,
}) {
  const regex = /\.pdf$/g;
  const regexMP4 = /\.mp4$/g;
  return item.content ? (
    <div style={{ display: "flex" }}>
      <div
        style={{
          backgroundColor:
            item.eventType === "sysmsg"
              ? item.action === "chat_ended" ||
                item.action === "chat_ended_admin"
                ? "#D6D8E8"
                : "#FFDCE4"
              : isUser
              ? "rgb(197 197 197 / 34%)"
              : "#F6F7FA",
          borderRadius: "10px",
          width:
            id &&
            (name.split(".")[name.split(".").length - 1].includes("mp3") ||
              name.split(".")[name.split(".").length - 1].includes("ogg")) &&
            "100%",
          maxWidth: id
            ? name
                .split(".")
                [name.split(".").length - 1].toLowerCase()
                .includes("mp4") ||
              name.split(".")[name.split(".").length - 1].includes("mp3") ||
              name.split(".")[name.split(".").length - 1].includes("ogg")
              ? "80%"
              : "80%"
            : item?.content?.urlContentType?.includes("audio/") ||
              item?.content?.urlContentType?.includes("video/")
            ? "100%"
            : item.eventType === "sysmsg"
            ? "80%"
            : "60%",
          minWidth: item.eventType !== "sysmsg" && "20%",
          overflowWrap: "break-word",
          padding: "8px",
          marginBottom: "4px",
          alignItems: "center",
          justifyContent: isUser ? "" : "end",
          marginLeft:
            item.eventType === "sysmsg" ? "auto" : isUser ? "" : "auto",
          marginRight: item.eventType === "sysmsg" && "auto",
        }}
      >
        <div
          style={{ fontWeight: "600", fontSize: "14px", marginBottom: "2px" }}
        >
          {item.eventType === "sysmsg"
            ? ""
            : isUser
            ? currentChat && currentChat.contact
              ? currentChat.contact.firstname === "No especificado" &&
                currentChat.contact.lastname === "No especificado"
                ? "No especificado"
                : service.getName(currentChat.contact)
              : !currentChat?.customFields?.data?.find(
                  (el) =>
                    el._id === "text" &&
                    (el.label.trim().toLowerCase() == "nombre" ||
                      el.label.trim().toLowerCase() == "nombre completo" ||
                      el.label.trim().toLowerCase() == "apellido" ||
                      el.label.trim().toLowerCase() == "su nombre" ||
                      el.label.trim().toLowerCase() == "name" ||
                      el.label.trim().toLowerCase() == "first name" ||
                      el.label.trim().toLowerCase() == "full name" ||
                      el.label.trim().toLowerCase() == "fullname")
                )?.value
              ? "No especificado"
              : currentChat?.customFields?.data?.find(
                  (el) =>
                    el._id === "text" &&
                    (el.label.trim().toLowerCase() == "nombre" ||
                      el.label.trim().toLowerCase() == "nombre completo" ||
                      el.label.trim().toLowerCase() == "apellido" ||
                      el.label.trim().toLowerCase() == "su nombre" ||
                      el.label.trim().toLowerCase() == "name" ||
                      el.label.trim().toLowerCase() == "first name" ||
                      el.label.trim().toLowerCase() == "full name" ||
                      el.label.trim().toLowerCase() == "fullname")
                )?.value
            : agents.find((agent) => agent.id === item.sender)?.fullName}
        </div>
        {item.eventType === "sysmsg" ? (
          item.action === "chat_ended" || item.action === "chat_ended_admin" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                paddingRight: 16,
              }}
            >
              <div style={{ paddingLeft: 12 }}>
                <div style={{ fontSize: 12, fontWeight: 600 }}>
                  Atención finalizada ({item.date && item.date.split("T")[0]})
                </div>
                <div
                  style={{
                    fontSize: 12,
                    wordBreak: "break-word",
                    textAlign: "justify",
                  }}
                >
                  Motivo: <strong>{` ${message}`}</strong>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                paddingRight: 16,
              }}
            >
              <div>
                <img src={transferIcon} style={{ width: 22, height: 22 }} />
              </div>
              <div style={{ paddingLeft: 12 }}>
                <div style={{ fontSize: 12, fontWeight: 600 }}>
                  Atención transferida
                </div>
                <div
                  style={{
                    fontSize: 12,
                    wordBreak: "break-word",
                    textAlign: "justify",
                  }}
                >
                  Motivo: <strong>{` ${message}`}</strong>
                </div>
              </div>
            </div>
          )
        ) : id ? (
          regex.test(name) === true ? (
            <a
              href={`${environment.storage}/c/${id}`}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <div
                className="message-img-vl-agent"
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "8px",
                }}
              >
                <div
                  style={{
                    marginRight: "8px",
                  }}
                >
                  <InsertDriveFileIcon style={{ color: "#EA345F" }} />
                </div>
                <div style={{ color: "#EA345F", wordBreak: "break-word" }}>
                  {name}
                </div>
              </div>
            </a>
          ) : name
              .split(".")
              [name.split(".").length - 1].toLowerCase()
              .includes("mp4") ? (
            <video style={{ width: "100%" }} controls>
              <source
                src={`${environment.storage}/c/${id}`}
                type={"video/mp4"}
              />
            </video>
          ) : name.split(".")[name.split(".").length - 1].includes("mp3") ||
            name.split(".")[name.split(".").length - 1].includes("ogg") ? (
            <audio controls style={{ width: "100%" }}>
              <source
                src={`${environment.storage}/c/${id}`}
                type={"audio/mp3"}
              />
            </audio>
          ) : (
            <img
              src={`${environment.storage}/c/${id}`}
              alt=""
              style={{
                width: "100%",
                borderRadius: "10px",
                objectFit: "contain",
                maxHeight: 500,
              }}
              className="message-img-vl-agent"
              onClick={async () => {
                await handleSelectedImage(item);

                setIsImgView(true);
              }}
              onLoad={() => !currentChat.isPrevius && service.scrollToBottom()}
            />
          )
        ) : item?.content?.url &&
          item?.content?.urlContentType?.includes("audio/") ? (
          <audio controls>
            <source
              src={item?.content?.url}
              type={item?.content?.urlContentType}
            />
          </audio>
        ) : item?.content?.urlContentType === "application/pdf" ? (
          <a
            href={item?.content?.url}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div
              className="message-img-vl-agent"
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "8px",
              }}
            >
              <div
                style={{
                  marginRight: "8px",
                }}
              >
                <InsertDriveFileIcon style={{ color: "#EA345F" }} />
              </div>
              <div style={{ color: "#EA345F", wordBreak: "break-word" }}>
                {!message ? "Archivo PDF" : message}
              </div>
            </div>
          </a>
        ) : item?.content?.urlContentType?.includes("video/") ? (
          <video width="320" height="240" controls>
            <source
              src={item?.content?.url}
              type={item?.content?.urlContentType}
            />
          </video>
        ) : (
          item?.content?.urlContentType?.includes("image/") && (
            <img
              src={item?.content?.url}
              alt=""
              style={{
                width: "100%",
                borderRadius: "10px",
                objectFit: "contain",
                maxHeight: 500,
              }}
              className="message-img-vl-agent"
              onClick={async () => {
                await handleSelectedImage(item);
                setIsImgView(true);
              }}
              onLoad={() => !currentChat.isNext && service.scrollToBottom()}
            />
          )
        )}

        <div style={{ marginBottom: "2px", wordBreak: "break-word" }}>
          {item?.content?.urlContentType === "application/pdf"
            ? ""
            : item.eventType !== "sysmsg" && message}
        </div>
        <div
          style={{
            textAlign: "right",
            fontFamily: "Roboto-Light",
            fontSize: "12px",
          }}
        >
          {item.eventType !== "sysmsg" &&
            new DateObject(new Date(date)).format("hh:mm a")}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );

  async function handleSelectedImage(image) {
    return setSelectedImage(image);
  }
}

export default ChatItem;
